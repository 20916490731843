import React from 'react'

const Manual = props => (
  <svg
  id="prefix__Capa_1"
  x={0}
  y={0}
  viewBox="0 0 28.26 22.38"
  xmlSpace="preserve"
  {...props}
>
  <style>{".prefix__st0{fill:#697f8e}.prefix__st1{fill:#c7e2fc}"}</style>
  <path
    className="prefix__st0"
    d="M24.86 2.93H6.33c-1.88 0-3.4 1.53-3.4 3.4v12.65c0 1.88 1.52 3.4 3.4 3.4h18.53c1.88 0 3.4-1.53 3.4-3.4V6.33c0-1.88-1.53-3.4-3.4-3.4z"
  />
  <path
    className="prefix__st1"
    d="M9.06 9.71c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.4.9-.9.9zM22.12 9.71h-10.3c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h10.3c.5 0 .9.4.9.9s-.4.9-.9.9zM22.12 12.45H9.06c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h13.06c.5 0 .9.4.9.9s-.4.9-.9.9zM22.12 15.18H9.06c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h13.06c.5 0 .9.4.9.9s-.4.9-.9.9zM22.12 17.92c.5 0 .9-.4.9-.9s-.4-.9-.9-.9-.9.4-.9.9.4.9.9.9zM19.37 17.92H9.06c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h10.3c.5 0 .9.4.9.9s-.39.9-.89.9z"
  />
  <g>
    <path
      d="M21.93 0H3.4C1.52 0 0 1.53 0 3.4v12.65c0 1.88 1.52 3.4 3.4 3.4h18.53c1.88 0 3.4-1.53 3.4-3.4V3.4c0-1.87-1.53-3.4-3.4-3.4z"
      fill="#adcbdb"
    />
  </g>
  <g opacity={0.8}>
    <path
      className="prefix__st0"
      d="M6.14 6.78c-.5 0-.9-.4-.9-.9s.4-.9.9-.9.9.4.9.9-.41.9-.9.9zM19.19 6.78H8.89c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h10.3c.5 0 .9.4.9.9s-.4.9-.9.9zM19.19 9.52H6.14c-.5 0-.9-.4-.9-.9s.4-.9.9-.9H19.2c.5 0 .9.4.9.9s-.41.9-.91.9zM19.19 12.26H6.14c-.5 0-.9-.4-.9-.9s.4-.9.9-.9H19.2c.5 0 .9.4.9.9s-.41.9-.91.9zM19.19 14.99c.5 0 .9-.4.9-.9s-.4-.9-.9-.9-.9.4-.9.9.4.9.9.9zM16.44 14.99H6.14c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h10.3c.5 0 .9.4.9.9s-.4.9-.9.9z"
    />
  </g>
  <g opacity={0.2}>
    <path
      className="prefix__st0"
      d="M3.31 16.14V3.31C3.31 1.48 4.79 0 6.61 0h-3.3C1.48 0 0 1.48 0 3.31v12.84c0 1.83 1.48 3.31 3.31 3.31h3.31a3.322 3.322 0 01-3.31-3.32z"
    />
  </g>
</svg>
)

export default Manual
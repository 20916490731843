import React from 'react'
import { TitleStroke } from '../../../components/_titles/_header_title_stroke'
import { Picture } from 'react-responsive-picture'

import Claim from "../../../components/_claim/claim";
import MadeInMexico from "../../../components/svg/commons/hecho_en_mexico"

function ModelApp(_props){

    let modelo = _props._model

    return (
        <div className="c-model_detail_header">
            <Claim textHtml="!Agua caliente para todos!" />
            <div className="c-model_header_title">
                <TitleStroke text={_props._model.data.titulo_header.text} textStroke="cinsa" colorStroke="#FFFFFF" colorText="#FFFFFF"/>
                <p className="c-model_header_description">{_props._model.data.claim.text}</p>
                {_props._model.data.made_in_mexico &&  <MadeInMexico /> }
                
            </div>
            <div className="c-model_header_bg">
                <Picture
                    sources = {[
                    {
                        srcSet: modelo.data.header_image.xs.url,
                        media: "(max-width: 767px)",
                    },
                    {
                        srcSet: modelo.data.header_image.sm.url,
                        media: "(max-width: 1023px)",
                    },
                    {
                        srcSet: modelo.data.header_image.md.url,
                        media: "(max-width: 1280px)",
                    },
                    {
                        srcSet: modelo.data.header_image.lg.url,
                        media: "(max-width: 1919px)",
                    },
                    {
                        srcSet: modelo.data.header_image.url,
                        media: "(min-width: 1920px)",
                    },
                    ]}
                />
            </div>
        </div>
    )
}
export default ModelApp
import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { Picture } from 'react-responsive-picture'
import CinsaLogo from '../../../components/svg/commons/cinsa_logo'
import { SliderSlickButton } from '../../../components/buttons/_button_slider_slick'
import CinsaFlame from '../../../components/svg/commons/cinsa_flame'

//=============
// I M A G E S
//=============
import img_services from '../../../../static/assets/images/model_detail/dimensions/cinsa_detalle_modelo_dimensiones_servicios.svg'
import img_width from '../../../../static/assets/images/model_detail/dimensions/cinsa_detalle_modelo_dimensiones_anchura_total.svg'
import img_weight from '../../../../static/assets/images/model_detail/dimensions/cinsa_detalle_modelo_dimensiones_peso.svg'
import img_height from '../../../../static/assets/images/model_detail/dimensions/cinsa_detalle_modelo_dimensiones_altura_total.svg'
import img_diameter from '../../../../static/assets/images/model_detail/dimensions/cinsa_detalle_modelo_dimensiones_diametro.svg'
import img_capacity from '../../../../static/assets/images/model_detail/dimensions/cinsa_detalle_modelo_dimensiones_capacidad.svg'
import img_line_blue from '../../../../static/assets/images/model_detail/dimensions/cinsa_dimensiones_lineas_oscuras.png'
import img_line_white from '../../../../static/assets/images/model_detail/dimensions/cinsa_dimensiones_lineas_claras.png'
//=============
// I M A G E S
//=============
const ModelDimensions = (props) => {

    const [versionsArray, setVersionsArray] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(1);
    let versions = props._versions;
    let model = props._item.data.name.text;
    let modelHeader = props._item.data.titulo_header.text;
    let modelUid = props._item.uid;

    useEffect(()=>{

        let versions_array = [];

        if(versions !== undefined) {
            versions.map((element) => {
                let services, capacity, width, diameter, height, weight, power_Tension;
                element.data.body.map((item) => {
                    if(item.__typename == "PrismicVersionBodySummary"){
                        services = item.items[0] !== undefined ? item.items[0].description : '';
                        capacity = item.items[1] !== undefined ? item.items[1].description : '';
                        width = item.items[2] !== undefined ? item.items[2].description : '';
                        diameter = item.items[3] !== undefined ? item.items[3].description : '';
                        height = item.items[4] !== undefined ? item.items[4].description : '';
                        weight = item.items[5] !== undefined ? item.items[5].description : '';
                        power_Tension = item.primary.bottom_text
                    }
                });

                versions_array.push({
                    'uid': element.uid,
                    'order': element.data.sort_index,
                    'data':{
                        'title':{
                            'text': element.data.title.text
                        },
                        'image': element.data.image_dimensions,
                        'services': services,
                        'capacity': capacity,
                        'width': width,
                        'diameter': diameter,
                        'height': height,
                        'weight': weight, 
                        'power_Tension': power_Tension
                    }

                });
            });
            setVersionsArray(versions_array)
        }
    },[]);

    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: ( index ) => { setCurrentSlide(index + 1) },
        nextArrow: <SliderSlickButton _className={`slick-button-next ${versionsArray && currentSlide === versionsArray.length ? 'disabled' : ''}`}/>,
        prevArrow: <SliderSlickButton _className={`slick-button-prev ${versionsArray && currentSlide === 1 ? 'disabled' : ''}` } />
    };

    function get_item(_item, _title, _image) {
        return (
            <div className="detail_dimensions_slide_item">
                <div className="detail_dimensions_item_image">
                    <Picture src={ _image } alt="Dimension Icon"/>
                </div>
                <div className="detail_dimensions_item_info">
                    <div className="detail_dimensions_item_value" dangerouslySetInnerHTML={{ __html: _item.html }} />
                    <div className="detail_dimensions_item_title">{ _title }</div>
                </div>
            </div>
        )
    }

    function get_slide(_item, _key) {
        return (
            <div key={`carousel-${_key}`} className={`detail_dimensions_slide`}>
                <div className="detail_dimensions_slide_image">
                    <Picture
                        sources = {[
                        {
                            srcSet: _item.data.image.xs.url,
                            media: "(max-width: 767px)",
                        },
                        {
                            srcSet: _item.data.image.sm.url,
                            media: "(max-width: 1919px)",
                        },
                        {
                            srcSet: _item.data.image.url,
                            media: "(min-width: 1920px)",
                        }
                        ]}
                    />
                </div>
                <div className="detail_dimensions_slide_content">
                    <div className={`detail_dimensions_slide_version ${modelUid}`}>{ _item.data.title.text }</div>
                    <div className="detail_dimensions_slide_items">
                        <div className="detail_dimensions_slide_items-top">
                            { get_item(_item.data.services, 'Servicios', img_services) }
                            { get_item(_item.data.height, 'Altura total', img_height) }
                            { get_item(_item.data.weight, 'Peso', img_weight) }
                        </div>
                        <div className="detail_dimensions_slide_items-bottom">
                            { get_item(_item.data.capacity, 'Capacidad', img_capacity) }
                            { get_item(_item.data.width, 'Ancho total', img_width) }
                            { get_item(_item.data.diameter, 'Diámetro', img_diameter) }
                        </div>
                        <div className="detail_power_tension" dangerouslySetInnerHTML={{ __html: _item.data.power_Tension.html }} />
                    </div>
                </div>
                <div className="detail_dimensions_slide_modelVersion">
                    <CinsaFlame />
                    <span>{ model }</span>
                    <span>{ _item.data.title.text }</span>
                </div>
            </div>
        )
    }

    return (
        <div className="detail_dimensions">
            <div className="detail_dimensions_container">
                <div className="detail_dimensions_bg">
                    <div className="detail_dimensions_bg-mask">
                        <div className="detail_dimensions_bg-mask_img">
                            <Picture src={ img_line_blue } alt="Cinsa Lineas Azules"/>
                        </div>
                    </div>
                </div>
                <div className="detail_dimensions_image_lines">
                    <Picture src={ img_line_blue } alt="Cinsa Lineas Azules"/>
                    <Picture src={ img_line_white } alt="Cinsa Lineas Blancas"/>
                </div>
                <div className="detail_dimensions_model">
                    <h2>
                        <span>CINSA</span>
                        { modelHeader }
                    </h2>
                </div>
                <div className="detail_dimensions_copy">dimensiones</div>
                <div className="detail_dimensions_count">
                    <span className="dimensions_count_current">{ currentSlide > 9 ? `${currentSlide}` : `0${currentSlide}`}</span>
                    <span className="dimensions_count_line"></span>
                    <span className="dimensions_count_total">{ versionsArray.length > 9 ? `${versionsArray.length}` : `0${versionsArray.length}`}</span>
                </div>
                <div className="detail_dimensions_slider">
                    <Slider { ...settings } >
                        {
                            versionsArray.map((data, _key)=>{
                                return (
                                    get_slide(data,_key)
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </div>

    )
}
export default ModelDimensions
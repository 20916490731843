import React from 'react'

const Maintenance = props => (
  <svg
  id="prefix__Capa_1"
  x={0}
  y={0}
  viewBox="0 0 26.06 28.87"
  xmlSpace="preserve"
  {...props}
>
  <style>
    {
      ".prefix__st3{opacity:.7}.prefix__st4{fill:#1e62aa}.prefix__st7{fill:#001f5b}"
    }
  </style>
  <path
    d="M7.03 4.5v5.17c0 3.32 2.69 6 6 6s6-2.69 6-6V4.5h-12z"
    fill="#fdd7ad"
  />
  <path
    d="M13.03 15.68c.6 0 1.18-.09 1.72-.25a5.994 5.994 0 01-4.28-5.75V4.5H7.03v5.17c0 3.32 2.69 6.01 6 6.01z"
    fill="#eda874"
  />
  <path
    d="M19.45 15.68H6.61A6.61 6.61 0 000 22.29v6.58h26.06v-6.58a6.61 6.61 0 00-6.61-6.61z"
    fill="#2977bc"
  />
  <g className="prefix__st3">
    <path
      className="prefix__st4"
      d="M19.45 15.68h-6.41l.13 13.19h12.9v-6.58c-.01-3.65-2.97-6.61-6.62-6.61z"
    />
  </g>
  <path
    d="M11.11 0h7.63c.17 0 .3.13.3.3v3.74c0 1.66-1.34 3-3 3H7.03V4.08C7.03 1.83 8.86 0 11.11 0z"
    fill="#47494c"
  />
  <path
    d="M15.32 0h-4.21C8.86 0 7.03 1.83 7.03 4.08v2.96h3.51V4.78A4.78 4.78 0 0115.32 0z"
    fill="#303133"
  />
  <g className="prefix__st3">
    <path
      className="prefix__st7"
      d="M15.92 15.68h-5.73l2.38 2.26c.27.26.7.26.97 0l2.38-2.26z"
    />
  </g>
  <path
    className="prefix__st4"
    d="M7.03 24.72c-.5 0-.9.4-.9.9v3.26h1.8v-3.26c0-.5-.4-.9-.9-.9z"
  />
  <g className="prefix__st3">
    <path
      className="prefix__st7"
      d="M19.04 24.72c-.5 0-.9.4-.9.9v3.26h1.8v-3.26c0-.5-.41-.9-.9-.9z"
    />
  </g>
</svg>
)

export default Maintenance
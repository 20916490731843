import React from 'react'

const DataSheet = props => {
  return (
    <svg
      id="prefix__Capa_1"
      x={0}
      y={0}
      viewBox="0 0 20.75 26.79"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#697f8e}"}</style>
      <path
        d="M20.21 5.97L15 .58C14.64.21 14.15 0 13.63 0H1.9A1.9 1.9 0 000 1.9v22.99c0 1.05.85 1.9 1.9 1.9h16.95a1.9 1.9 0 001.9-1.9V7.29c0-.49-.19-.96-.54-1.32z"
        fill="#adcbdb"
      />
      <path
        className="prefix__st1"
        d="M20.21 5.97L15 .58c-.19-.19-.41-.34-.65-.44v5.32c0 .79.62 1.42 1.39 1.42h4.97a2 2 0 00-.5-.91z"
      />
      <g opacity={0.8}>
        <path
          className="prefix__st1"
          d="M4.35 11.9c.5 0 .9-.4.9-.9s-.4-.9-.9-.9-.9.4-.9.9c0 .49.4.9.9.9zM7.1 11.9h9.3c.5 0 .9-.4.9-.9s-.4-.9-.9-.9H7.1c-.5 0-.9.4-.9.9s.4.9.9.9zM16.4 12.83H4.35c-.5 0-.9.4-.9.9s.4.9.9.9H16.4c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM16.4 15.57H4.35c-.5 0-.9.4-.9.9s.4.9.9.9H16.4c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM16.4 18.31c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9zM13.65 18.31h-9.3c-.5 0-.9.4-.9.9s.4.9.9.9h9.3c.5 0 .9-.4.9-.9s-.4-.9-.9-.9z"
        />
      </g>
      <path
        className="prefix__st1"
        d="M3 24.89V1.9C3 .85 3.85 0 4.9 0h-3A1.9 1.9 0 000 1.9v22.99c0 1.05.85 1.9 1.9 1.9h3a1.9 1.9 0 01-1.9-1.9z"
        opacity={0.2}
      />
    </svg>
  )
}

export default DataSheet
import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import GetBodyItem from '../../../components/_helpers/_get_body_item'
import HeaderTitle from '../../../components/_titles/_header_title'
import { Picture } from 'react-responsive-picture'
import { SliderSlickButton } from '../../../components/buttons/_button_slider_slick'
import { useCurrenDevice, DEVICES } from '../../../hooks/useCurrentDevice'

const ModelAdvantages = (_props) => {

    let modelo = _props._model;
    let getBodyItem = new GetBodyItem();
    let data = getBodyItem.get_item(modelo,"PrismicModelBodyVentajas");
    let itemCount = 0;
    let valueCount;

    const [currentSlide, setCurrentSlide] = useState(1);
    const [issetIE, setIssetIE] = useState();
    const [device] = useCurrenDevice()


    useEffect(()=>{
        if (document !== null){
            setIssetIE(document.documentMode ? document.documentMode : 99);
        }
    },[]);

    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        afterChange: ( index ) => { setCurrentSlide(Math.round(index / 4) + 1) },
        nextArrow: <SliderSlickButton _red={ true } _className={`slick-button-next ${data && currentSlide === data.items.length / 4 ? 'disabled' : ''}`}/>,
        prevArrow: <SliderSlickButton _red={ true } _className={`slick-button-prev ${data && currentSlide === 1 ? 'disabled' : ''}` } />,
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    function get_item(_item, _key){
        let classReverse = _key % 2 == 0 ? 'item-reverse' : '';
        let onlyImage = _item.advantage_only_image ? 'only-image' : '';

        const styleTitle = {color: `${_item.title_color}`}
        const styleDescription = {color: `${_item.description_color}`}
        const styleBackground = {backgroundColor: `${_item.background_color}`}


        return (
            <div key={`carousel-${_key}`} className={`detail_advantage_item ${ classReverse } ${ onlyImage }`}>
                <div className="detail_advantage_item_image">
                    <Picture src={ _item.advantage_image.url } alt={ _item.advantage_image.alt }/>
                </div>
                <div className="detail_advantage_item_content" style={ styleBackground }>
                    <div className="detail_advantage_item_title" style={ styleTitle }>
                        { _item.advantage_title_stroke.text }
                        <span style={{ color: "#FFFFFF" }}>{ _item.advantage_title.text }</span>
                    </div>
                    <div className="detail_advantage_item_description" style={ styleDescription }>{ _item.advantage_description.text }</div>
                </div>
            </div>
        )
    }

    return ( data &&
        <div className="detail_advantages">
            <div className="detail_advantages_container">
                <HeaderTitle
                    title="VENTAJAS"
                    subtitle="Descubre las ventajas de tu Cinsa"
                    isMainTitle={false}
                />
                <div className="detail_advantages_item_count">
                    <span className="advantages_count_current">{ currentSlide > 9 ? `${currentSlide}` : `0${currentSlide}`}</span>
                    <span className="advantages_count_line"></span>
                    <span className="advantages_count_total">{ data.items.length / 4 > 9 ? `${Math.ceil(data.items.length / 4)}` : `0${Math.ceil(data.items.length / 4)}`}</span>
                </div>
                <div className="detail_advantages_slider">
                    <Slider { ...settings } >
                        {
                            device === DEVICES.mobile ?
                                data.items.map((data, _key)=>{
                                    if( !data.advantage_only_image){
                                        itemCount++;
                                        return (
                                            get_item(data,itemCount)
                                        )
                                    }
                                })
                            :
                                data.items.map((data, _key)=>{
                                    if(!data.advantage_only_image){
                                        itemCount++
                                        valueCount = itemCount;
                                    }else{
                                        valueCount = 'only-image-' + _key;
                                    }
                                    return (
                                        get_item(data,valueCount)
                                    )
                                })
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default ModelAdvantages
import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import GetBodyItem from '../../../components/_helpers/_get_body_item'
import HeaderTitle from '../../../components/_titles/_header_title'
import { Picture } from 'react-responsive-picture'
import { SliderSlickButton } from '../../../components/buttons/_button_slider_slick';

function ModelFeatures(_props){

    const [currentSlide, setCurrentSlide] = useState(1);
    const [data, setData] = useState(null);

    useEffect(()=>{
        let modelo = _props._model;
        let getBodyItem = new GetBodyItem();
        let dataFeature = getBodyItem.get_item(modelo,"PrismicModelBodyCaracteristicas");
        if( dataFeature ){
            setData(dataFeature);
        }
    },[])

    function get_item(_item,_key){
        return(
            <div key={`model_features_item_${_key}`} className="detail_features_item">
                <div className="detail_features_item_container">
                    <div className="detail_features_item_image">
                        <Picture
                            sources = {[
                            {
                                srcSet: _item.feature_img.xs.url,
                                media: "(max-width: 767px)",
                            },
                            {
                                srcSet: _item.feature_img.sm.url,
                                media: "(max-width: 1023px)",
                            },
                            {
                                srcSet: _item.feature_img.md.url,
                                media: "(max-width: 1365px)",
                            },
                            {
                                srcSet: _item.feature_img.lg.url,
                                media: "(max-width: 1919px)",
                            },
                            {
                                srcSet: _item.feature_img.url,
                                media: "(min-width: 1920px)",
                            },
                            ]}
                        />
                    </div>
                    <div className="detail_features_item_info">
                        <div className="detail_features_item_title" dangerouslySetInnerHTML={{ __html: _item.feature_title.html }} />
                        <div className="detail_features_item_description" dangerouslySetInnerHTML={{ __html: _item.feature_description.html }}  />
                    </div>
                </div>

            </div>
        )
    }
    const settings = {
        customPaging: function(i) {
            return (
              <span className='pagination__bullet'></span>
            );
        },
        dots: true,
        dotsClass: 'pagination__dots',
        lazyLoad: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        afterChange: ( index ) => { setCurrentSlide(index + 1) },
        nextArrow: <SliderSlickButton _className={`slick-button-next`}/>,
        prevArrow: <SliderSlickButton _className={`slick-button-prev`} />,
        responsive: [
            {
                breakpoint: 1020,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return ( data ?
        <div className="detail_features">
            <div className="detail_features_container">
                <HeaderTitle
                    title="CARACTERÍSTICAS"
                    subtitle="Conoce las partes de tu Cinsa"
                    isMainTitle={false}
                    index={_props.index}
                />
                <div className="detail_features_slider">
                    <Slider {...settings}>
                        {
                            data.items.map((data, _key)=>{
                                return (
                                    get_item(data,_key)
                                )
                            })
                        }
                    </Slider>
                    {/* <div className="detail_features_item_count">
                        <span className="feature_count_current">{ currentSlide > 9 ? `${currentSlide}` : `0${currentSlide}`}</span>
                        <span className="feature_count_line"></span>
                        <span className="feature_count_total">{ data.items.length > 9 ? `${data.items.length}` : `0${data.items.length}`}</span>
                    </div> */}
                </div>
            </div>
        </div>: null
    )
}
export default ModelFeatures
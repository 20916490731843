import React from "react"

function SvgPlay (props) {
    return (
        <svg width={155.5} height={155.5} {...props}>
            <path
                fillRule="evenodd"
                fill="#FFF"
                d="M69.531 62.45l15.018 15.018-15.018 15.019V62.45z"
            />
            <path
                stroke="#FFF"
                strokeWidth={3}
                fill="none"
                d="M77 1.969c41.421 0 75 33.578 75 75 0 41.421-33.579 75-75 75s-75-33.579-75-75c0-41.422 33.579-75 75-75z"
            />
        </svg>
    )
}

export default SvgPlay
import React, { useState, useEffect } from "react"
import ReactPlayer from 'react-player/youtube'

import GetBodyItem from '../../../components/_helpers/_get_body_item'
import { useWindowSize } from '../../../hooks/use_window-size'
import IconPlay from '../../../components/svg/commons/play'
import HeaderTitle from '../../../components/_titles/_header_title'



const ModelYoutube = ({model}) => {
    const [containerWhidth, setContainerWhidth] = useState(0);
    const windowSize = useWindowSize();
    const [videoActive, setVideoActive] = useState(false)
    const [heightVideo, setHeightVideo] = useState(360)

    let getBodyItem = new GetBodyItem();
    let data = getBodyItem.get_item(model,"PrismicModelBodyVideoYoutube").primary;


    useEffect(() => {
        setContainerWhidth(window.innerWidth)

        if(window.innerWidth < 768) {
            setHeightVideo(360);
            return
        }

        setHeightVideo('100%');
    },[windowSize])

    if(data === undefined) return <></>;

    return (
        <section className="detail_youtube">
            <HeaderTitle
                title="VIDEO"
                subtitle="Conoce nuestras ventajas Cinsa"
                isMainTitle={false}
                index="03"
            />
            <div className="detail_youtube_container">
                { !videoActive && (
                    <div className="detail_youtube_banner" style={{backgroundImage: `url(${data.image.url})`}}>
                        <IconPlay onClick={() => setVideoActive(true)}/>
                        <h3>{data.title.text}</h3>
                    </div>)
                }

                {
                    videoActive && <ReactPlayer 
                                        controls={true}
                                        width={containerWhidth}
                                        playing={true}
                                        height={heightVideo}
                                        url={`https://www.youtube.com/watch?v=${data.youtube_code.text}`}
                                        onEnded={() => setVideoActive(false)}/>
                }

            </div>
        </section>
    )
}

export default ModelYoutube;
import React from 'react';

import Flame from "../../../components/svg/commons/flame"

export const ModelStorytelling = ({ data }) => {

    return (
        <div className="detail_storytelling">
            <img className="detail_storytelling_img" src={data.primary.storytelling_image.url} alt={data.primary.storytelling_image.url}/>
            <div className="detail_storytelling_text">
                <p className="detail_storytelling_title_top">
                    <Flame />
                    { data.primary.storytelling_subtitle.text }
                </p>
                <h2 className="detail_storytelling_title">{ data.primary.storytelling_title.text }</h2>
                { data.primary.subtitle_second.text !== "" && <p className="detail_storytelling_title_bottom">{ data.primary.subtitle_second.text }</p>}
            </div>
        </div>
    )
}
import React from 'react'

const Dealers = props => (
  <svg viewBox="0 0 18.8 27.62" {...props}>
    <ellipse cx={9.4} cy={24.65} rx={8.5} ry={2.97} fill="#2977bc" />
    <path
      d="M11.9 21.81c-.79-.08-1.63-.13-2.5-.13-4.69 0-8.5 1.33-8.5 2.97 0 1.64 3.81 2.97 8.5 2.97.87 0 1.71-.05 2.5-.13-3.47-.37-6-1.5-6-2.84 0-1.34 2.53-2.47 6-2.84z"
      fill="#1e62aa"
    />
    <path
      d="M9.4 0C4.22 0 0 4.17 0 9.3c0 5.68 6.28 12.54 8.56 14.39.25.21.55.31.84.31.29 0 .59-.1.83-.3 2.29-1.86 8.56-8.71 8.56-14.4C18.8 4.17 14.58 0 9.4 0z"
      fill="#feb820"
    />
    <path
      d="M9.4 0v24c.29 0 .59-.1.83-.3 2.29-1.86 8.56-8.71 8.56-14.4C18.8 4.17 14.58 0 9.4 0z"
      fill="#eda42e"
    />
    <circle cx={9.4} cy={9.4} r={4.82} fill="#eef0f9" />
  </svg>
)

export default Dealers
import React from 'react'

import DetailLogo from '../detail_logo';
import GetBodyItem from '../../../components/_helpers/_get_body_item'

import { Picture } from 'react-responsive-picture';

function ModelCertificates(_props){
    let modelo = _props._model;
    
    let getBodyItem = new GetBodyItem();
    let data = getBodyItem.get_item(modelo,"PrismicModelBodyCertificados")
    let items = null;
    let title = null
    if(data)
    {
        items = data.items;
        title = data.primary.certificado_title.text;
    }

    
    return (
        <div className="detail_certificates model_detail_section">
            <div className="detail_certificates_copy product__bg__gradient--h">
                <div className="detail_certificates_copy_title">
                    <div className="detail_certificates_copy_titlestage">    
                        {title}
                    </div>
                </div>
                <div className="detail_certificates_copy_certificates_container">
                    { items.map((_item, _key)=>{
                            return (
                                <img key={`detail_certificates_${_key}`}className="detail_certificates_copy_image" src={_item.certificado_imagen.url}></img>
                            )
                        })
                    }
                </div>
            </div>
            <div className="detail_certificates_bottom">
                <div className="detail_certiticates_logo">
                    <DetailLogo _name={modelo.data.name.text} _title="Certificados de calidad"></DetailLogo>
                </div>
                <div className="detail_certificates_logo_container">
                    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_ance_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_ance_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_ance.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                        
                    </div>    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_conuee_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_cunee_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_conuee.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                       
                    </div>    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_desing_certified_sa_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_desing_certified_sa_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_desing_certified_sa.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_ema_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_ema_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_ema.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_esr_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_esr_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_esr.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_hlw_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_hlw_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_hlw.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_industria_limpia_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_industria_limpia_mb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_industria_limpia.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                       
                    </div>    
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_iso_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_iso_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_iso.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_nom_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_nom_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_nom.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_sener_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_sener_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_sener.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>
                    <div className="detail_certificates_logo_image_container">
                    <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_sige_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_sige_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_sige.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>
                    <div className="detail_certificates_logo_image_container">
                        <Picture
                            sources = {[
                            {
                                srcSet: '/assets/images/model_detail/certificados/mb/calorex_certificado_ul_mb.png',
                                media: "(max-width: 768px)",
                            },
                            {
                                srcSet: '/assets/images/model_detail/certificados/tb/calorex_certificado_ul_tb.png',
                                media: "(max-width: 1365px)",
                            },
                            
                            {
                                srcSet: '/assets/images/model_detail/certificados/dk/calorex_certificado_ul.png',
                                media: "(min-width: 1366px)",
                            }
                                
                            ]}
                        />
                        
                    </div>    
                </div>
            </div>
            
        </div>
    )
}
export default ModelCertificates
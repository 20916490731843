import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'

import ModelHeader  from '../content/model_detail/_sections/_model_header';
import Storytelling from '../content/home/storytelling/storytelling';
import Access from '../content/home/footer/access';
import { ModelStorytelling }  from '../content/model_detail/_sections/_model_storytelling';
import HomeProducts from '../content/home/products/home_products'
import ModelMaintenance from '../content/model_detail/_sections/_model_maintenance';
import ModelFeatures from '../content/model_detail/_sections/_model_features';
import ModelAdvantages  from '../content/model_detail/_sections/_model_advantages';
import ModelDimensions from '../content/model_detail/_sections/_model_dimensions';
import ModelApp from '../content/model_detail/_sections/_model_app';
import ModelSystems from '../content/model_detail/_sections/_model_systems';
import ModelConocelo from '../content/model_detail/_sections/_model_conocelo';
import ModelCertificates from '../content/model_detail/_sections/_model_certificates';
import ModelQuickAccess from '../content/model_detail/_sections/_model_quick_access';

import '../styles/pages/model/model.scss';

import Head from '../components/head'
import ModelVideo from '../content/model_detail/_sections/_model_video';
import ModelLeed from '../content/model_detail/_sections/_model_leed';
import ModelYoutube from '../content/model_detail/_sections/_model_youtube'
import { useCurrenDevice, DEVICES } from '../hooks/useCurrentDevice';
import { usePrismModels } from '../hooks/use_prism-models';
import { Link } from 'gatsby';

import GetBodyItem from '../components/_helpers/_get_body_item'

const  CalentadoresDetail=(props)=> {
    let model = props.pageContext.item.node;
    const allModels = usePrismModels().nodes;
    if(model.data===undefined){
        console.log('ERROR 2 ',model.uid)
    }
    let versions = props.pageContext.versions;

    const [device] = useCurrenDevice();
    const [familyModels, setFamilyModels] = useState([]);

    let body_info = new GetBodyItem();
    let metas_from_prismic = body_info.get_item(model,"PrismicModelBodyMetas");
    let storytelling_data = body_info.get_item(model,"PrismicModelBodyStorytelling2");
    let youtubeData = body_info.get_item(model,"PrismicModelBodyVideoYoutube").primary;

    let metas= {
        title:'Cinsa modelo',
        description: 'Cinsa modelo',
        image:''
    }
    if(metas_from_prismic)
    {
        metas.title = metas_from_prismic.primary.title.text
        metas.description = metas_from_prismic.primary.description.text
        metas.image= metas_from_prismic.primary.image_share.url
    }
    useEffect(() => {
        if(device === DEVICES.desktop) return;
        setFamilyModels(allModels.filter(item => item.data.category.uid === model.data.category.uid))
    }, [device])
    // console.log('model detal', model)
    return (

        <Layout _class="c-model_detail">
            {/* <Head
                title={metas.title}
                pathname={`/calentadores/${model.uid}`}
                description={metas.description}
                image={metas.image}
            /> */}
            
            <ModelHeader _model={model} />
            <ModelStorytelling data={storytelling_data} />
            <ModelQuickAccess _model={model}></ModelQuickAccess>
            {/* <Storytelling data={storytelling_data} /> */}
            <ModelAdvantages _model={model} />
            <ModelDimensions _versions={versions} _item={model}></ModelDimensions>
            <ModelYoutube model={model} />
            <ModelFeatures _model={model} index={ youtubeData === undefined ? '03' : '04'}/>
            <ModelMaintenance index={ youtubeData === undefined ? '04' : '05'}/>
            <Access/>
            <HomeProducts index={ youtubeData === undefined ? '05' : '06'}/>
            {/* <ModelVideo _model={model}></ModelVideo>
            
            <ModelLeed _model={model}></ModelLeed>
            <ModelApp _model={model} ></ModelApp>
            <ModelSystems _model={model} ></ModelSystems>
            <ModelConocelo _model={model}></ModelConocelo>
            <ModelCertificates _model={model}></ModelCertificates> */}
        </Layout>)

}

export default CalentadoresDetail
import React,{useRef,useEffect,useState} from 'react'
import { Link } from 'gatsby'

import DataSheet from '../../../components/svg/commons/data_sheet'
import Maintenance from '../../../components/svg/maintenance/maintenance'
import Manual from '../../../components/svg/commons/manual'
import Distribuidores from '../../../components/svg/maintenance/dealers'

import CssClassManager from '../../../components/_helpers/_css_class_manager'


function ModelQuickAccess(_props){    
    let _model = _props._model;
    let _section = 'menu_svg_'
    const menu = useRef(null);
    // const menu_opener = useRef(null);
    let body =  _model.data.body;

    let found = false;
    let item_count = 0;    
    let link_technical = null;
    let link_manual = null;
    let fixed = false;
    let cssClassManager = new CssClassManager()
    let valueScroll = 0;
    
    const [scrollToFixed, setScrollToFixed] = useState();
    const [scrollToFixedPlus, setScrollToFixedPlus] = useState();
    
    
    while (!found && item_count<body.length)
    {
        let item = body[item_count];
        // console.log('item---s',item);
        if(item.__typename ==="PrismicModelBodyArchivos")
        {
            found = true;
            
            link_technical = item.primary.technical.url
            link_manual = item.primary.manual.url         
        }
        item_count++;
    }

    useEffect(()=>{
        fixed = false;
        if(window!==undefined)
        {
            const winWidth =window.innerWidth;
            console.log('winWidth',winWidth);
            if(winWidth<768){
                setScrollToFixed(0.10142);
                setScrollToFixedPlus(0.0918);
            }
            else{
                setScrollToFixed(0.1229);
                setScrollToFixedPlus(0.1122);               
            }            
        }
        // menu_opener.current.addEventListener('mouseenter',open_submenu);
        menu.current.addEventListener('mouseleave',close_submenu);
    },[])
    useEffect(()=>{
        window.addEventListener('scroll', listenToScroll)
    },[scrollToFixed]);
    
    function listenToScroll () {
        // if(window.innerWidth >= 1024) return;
        if(menu===null) return false;
        if(scrollToFixed===undefined) return false;

        const winScroll =document.body.scrollTop || document.documentElement.scrollTop
      
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight
      
        const scrolled = winScroll / height
        console.log('scrolled',scrolled)
        console.log('scrollToFixed',scrollToFixed)
        if(!fixed && scrolled>scrollToFixed){
            fixed=true;
            cssClassManager.add_class(menu.current,'detail_quick_access--visible')            
        }
        else{
            if(fixed && scrolled<scrollToFixedPlus)
            {
                fixed = false;
                cssClassManager.remove_class(menu.current,'detail_quick_access--visible')
            }
        }
        let windowY = window.pageYOffset;

        if( windowY > 100){
            if(window.innerWidth >= 1024) return
            if(windowY > valueScroll){
                cssClassManager.add_class(menu.current,'is-hidden');
            }else{
                cssClassManager.remove_class(menu.current,'is-hidden');
            }
            valueScroll = windowY;
            cssClassManager.add_class(menu.current,'bg-active');
        }else{
            cssClassManager.remove_class(menu.current,'bg-active');
        }
      }
    function open_submenu(){
        console.log('open_submenu');
        cssClassManager.add_class(menu.current,'sub_active');
    }
    function close_submenu(){
        console.log('close_submenu');
        cssClassManager.remove_class(menu.current,'sub_active');
    }
    return (
        <div className="detail_quick_access" ref={menu}>
            
                
            
            <div className="detail_quick_access_stage">
                { //link_technical!==null ?  
                    true ?  
                    <a className="detail_quick_access_item detail_quick_access_technical product__bg__gradient--h" href={link_technical} target="_blank">
                        <div className="detail_quick_access__logo">
                            <DataSheet section={_section} fill={`url(#${_section}_datasheet)`}></DataSheet>
                        </div>
                        <p className="detail_quick_access__info">Ficha técnica</p>
                    </a> : null
                }
                { 
                    link_manual!==null ? 
                        <a className="detail_quick_access_item detail_quick_access_manual product__bg__gradient--h" href={link_manual} target="_blank">
                            <div className="detail_quick_access__logo">
                                <Manual section={_section} fill={`url(#${_section}_manual)`}></Manual>
                            </div>
                            <p className="detail_quick_access__info">Manual</p>
                        </a>: null
                }
                <Link className="detail_quick_access_item detail_quick_access_maintenance product__bg__gradient--h" to='/solicita-ayuda'>
                    <div className="detail_quick_access__logo">
                        <Maintenance section={_section} fill={`url(#${_section}_maintenance)`}></Maintenance>
                    </div>
                    <p className="detail_quick_access__info">Mantenimiento</p>
                </Link>
                
                <Link className="detail_quick_access_item detail_quick_access_dealers product__bg__gradient--h" to='/distribuidores'>
                    <div className="detail_quick_access__logo">
                        <Distribuidores section={_section} fill={`url(#${_section}_dealers)`}></Distribuidores>
                    </div>
                    <p className="detail_quick_access__info">Distribuidores</p>
                </Link>
                {/* <a className="detail_quick_access_open" ref={menu_opener}>
                    <div className="detail_quick_access_open__linecontainer">
                        <div className="detail_quick_access_open__line detail_quick_access_open__line1"></div>
                        <div className="detail_quick_access_open__line detail_quick_access_open__line2"></div>
                        <div className="detail_quick_access_open__line detail_quick_access_open__line3"></div>
                    </div>                    
                </a>                 */}
            </div>
        </div>
    )
}
export default ModelQuickAccess